import gsap from 'gsap';
import { AmbientLight, Color, Vector2, DirectionalLight, DirectionalLightHelper } from 'three';

import { GetBy } from '../_app/cuchillo/core/Element';
import WebGLGroup from '../_app/cuchillo/3D/WebGLGroup';
import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import { isDebug } from '../_app/cuchillo/core/Basics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { LIGHT_SETTINGS } from './constants';
import SceneGLB from '../_app/cuchillo/3D/SceneGLB';

export default class Scene {
    static _running = false;
    static _initialized = false;
    static _rot = 0;
    static _tick = 0;
    static _seed = 0;

    static mouse = new Vector2();

    static dom;
    static head;

    static flotationGroup = new WebGLGroup({ zFactor: .15, yFactor: .036 });
    static scrollGroup = new WebGLGroup({ rotFactor: .044 });
    static group = new WebGLGroup();

    static material;

    static ambientLight;
    static directionalLight;
    static lightColor = new Color(LIGHT_SETTINGS.color);

    static init() {
        WebGLSketch.add(this.group);
        this.group.add(this.flotationGroup);
        this.flotationGroup.add(this.scrollGroup);

        this.init3dItems();
    }

    static start() {
        if (!this._running) this._running = true;
    }

    static stop() {
        if (this._running) this._running = false;
    }

    static add(element) {
        this.scrollGroup.add(element);
    }

    static remove(element) {
        this.scrollGroup.remove(element);
    }

    static init3dItems() {
        const dom = GetBy.selector('#scene-target [data-3d-head]')[0];
        this.dom = dom;
        const dom2 = GetBy.id('HeaderTarget');
        this.dom2 = dom2;

        this.ambientLight = new AmbientLight('#ffffff', 2);
        WebGLSketch.add(this.ambientLight);

        this.directionalLight = new DirectionalLight(this.lightColor, 1);
        WebGLSketch.add(this.directionalLight);
        this.directionalLight.position.x = LIGHT_SETTINGS.x;
        this.directionalLight.position.y = LIGHT_SETTINGS.y;
        this.directionalLight.position.z = LIGHT_SETTINGS.z;
        this.directionalLight.color.set(LIGHT_SETTINGS.color);
        this.directionalLight.intensity = LIGHT_SETTINGS.intensity;

        // if (isDebug) {
        //     var directionalLightHelper = new DirectionalLightHelper(this.directionalLight, 100); // El segundo parámetro es el tamaño del helper
        //     WebGLSketch.add(directionalLightHelper);
        // }

        // MODEL 
        // this.head = new ModelOBJ({
        //     model: '/assets/models/head.obj',
        //     diffusion: '/assets/models/head_diffuse_2.jpg',
        //     normal: '/assets/models/head_normal_2.jpg',
        //     roughness: '/assets/models/head_roughness_2.jpg',
        //     material: STANDARD_MATERIAL.clone(),
        //     dom,
        //     finalDom: dom2,
        //     cols: 4,
        //     yFactor: .036
        // });
        this.head = new SceneGLB({
            // model: '/assets/models/head_scaled.glb',
            model: '/assets/models/head.gltf',
            dom,
            finalDom: this.dom2,
            cols: 4,
            yFactor: .036
        });
        this.head.init().then(() => {
            this.add(this.head);
            this.resize();
            this.loop();
        });

        gsap.set(this.group.rotation, { y: -Math.PI * 3 });
        gsap.set(this.group.scale, { x: 0 });
        gsap.set(this.group.scale, { y: 0 });
        gsap.set(this.group.scale, { z: 0 });
    }

    static show(delay = 0, cb = () => { }) {
        if (this._initialized) return;

        gsap.to(this.group.scale, { x: 1, duration: 3, delay, ease: Ease.EASE_CUCHILLO_IN_OUT });
        gsap.to(this.group.scale, { y: 1, duration: 3, delay, ease: Ease.EASE_CUCHILLO_IN_OUT });
        gsap.to(this.group.scale, { z: 1, duration: 3, delay, ease: Ease.EASE_CUCHILLO_IN_OUT });
        gsap.to(this.group.rotation, {
            y: 0,
            delay,
            duration: 3,
            ease: Ease.EASE_CUCHILLO_IN_OUT
        });

        gsap.delayedCall(delay + 3.2, () => {
            this._initialized = true;
            this.head.isFinal = true;
            cb();
        })
    }

    static hide() {
        this.update(0);
    }

    static update(position = 0, speed = 0) {
        if (!this._initialized) return;

        const sp = Maths.clamp(Maths.map(speed, 0, 100, 0, 30), -30, 30);

        this.scrollGroup.rot.y = Maths.toRadians(position * .15);

        this.flotationGroup.pos.z = sp * 1.5;
        this.flotationGroup.pos.y = sp * .5;

        this.directionalLight.position.x = LIGHT_SETTINGS.x;
        this.directionalLight.position.y = LIGHT_SETTINGS.y;
        this.directionalLight.position.z = LIGHT_SETTINGS.z;
        this.directionalLight.color.set(LIGHT_SETTINGS.color);
        this.directionalLight.intensity = LIGHT_SETTINGS.intensity;
    }

    static loop() {
        if (!this._running) return;

        this.head.update();
        this.scrollGroup.update();
        this.flotationGroup.update();
    }

    static resize() {
        if (this.head) this.head.resize();
    }

    static dispose() {
        this.flotationGroup.remove(this.head);
        this.head.dispose();

        this.group.remove(this.flotationGroup);
        this.flotationGroup.dispose();

        WebGLSketch.remove(this.group);
        this.group.dispose();

        WebGLSketch.remove(this.ambientLight);
        this.ambientLight.dispose();

        WebGLSketch.remove(this.directionalLight);
        this.directionalLight.dispose();

        // WebGLSketch.remove(this.fixedLight);
        // WebGLSketch.remove(this.fixedLight.target);
        // this.fixedLight.dispose();
    }
}
