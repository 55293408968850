import { OrthographicCamera, PerspectiveCamera, Scene, Vector2, AxesHelper, Vector3, WebGLRenderer, sRGBEncoding, Clock, PCFSoftShadowMap, Group, ACESFilmicToneMapping } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { isDebug, isSmartphone, isTouch } from '../core/Basics';
import { GetBy } from '../core/Element';
import { Metrics } from '../core/Metrics';
import { Sizes } from '../core/Sizes';
import { CENTER, SETTINGS } from '../../../3D/constants';
import { Interaction } from '../core/Interaction';
import { Maths } from '../utils/Maths';

export default class WebGLSketch {
	_started = false;
	_paused = false;
	static renderer;
	static domElement;
	static scene;
	static camera;
	static clock;
	static size;
	static controls;
	static rotation = false;

	static group = new Group();
	static groupStatic = new Group();
	static mouse = new Vector2();
	static cameraGroup = new Group();
	static cameraPosition = CENTER.clone();
	static cameraLookAt = CENTER.clone();
	static cameraLookAtTarget = CENTER.clone();

	static defaults = {
		container: 'scene-three',
		antialias: true,
		alpha: false,
		ortho: false,
		camera2D: SETTINGS.camera2D,
		shadows: false,
		debug: isDebug,
		transparent: true,
		clearColorAlpha: 0,
		lookAt: SETTINGS.lookAt,
		cameraPos: SETTINGS.cameraPos,
		fov: SETTINGS.fov,
		controls: SETTINGS.controls,
		far: SETTINGS.far,
		near: SETTINGS.near,
		clearColor: SETTINGS.clearColor,
		clearColorAlpha: SETTINGS.clearColorAlpha
	}

	static init(opts = {}) {
		this.defaults = {
			...this.defaults,
			...opts
		};

		this.domElement = GetBy.id(this.defaults.container);
		const { width, height } = this.domElement.getBoundingClientRect();
		this.size = this.defaults.size ? this.defaults.size : new Vector2(width, height);

		this.renderer = new WebGLRenderer({
			canvas: this.domElement,
			antialias: this.defaults.antialias,
			alpha: this.defaults.alpha
		});

		this.renderer.toneMapping = ACESFilmicToneMapping;
		this.renderer.toneMappingExposure = 1.0;
		this.renderer.gammaOutput = true;

		this.renderer.setClearColor(this.defaults.clearColor, this.defaults.clearColorAlpha);
		this.renderer.setSize(this.size.x, this.size.y);
		this.renderer.setPixelRatio(Sizes.RATIO_CANVAS);

		if (this.defaults.outputEncoding) {
			this.renderer.outputEncoding = this.defaults.outputEncoding;
		}

		if (this.defaults.shadows) {
			this.renderer.shadowMap.enabled = true;
			this.renderer.shadowMap.type = PCFSoftShadowMap;
		}

		// if (this.defaults.ortho) {
		// 	this.camera = new OrthographicCamera(
		// 		-this.size.x / 2,
		// 		this.size.x / 2,
		// 		this.size.y / 2,
		// 		-this.size.y / 2,
		// 		this.defaults.near,
		// 		this.defaults.far,
		// 	);
		// } else {
		this.camera = new PerspectiveCamera(
			this.defaults.fov,
			this.size.x / this.size.y,
			this.defaults.near,
			this.defaults.far
		);

		this.camera.position.copy(CENTER);
		this.cameraPosition.copy(this.defaults.cameraPos);
		this.cameraGroup.position.copy(this.defaults.cameraPos);

		this.camera.lookAt(this.defaults.lookAt);
		this.cameraLookAt.copy(this.defaults.lookAt);
		this.cameraLookAtTarget.copy(this.defaults.lookAt);

		if (this.defaults.camera2D) {
			this.camera.fov = 2 * Math.atan(this.size.x / this.camera.aspect / (2 * this.defaults.cameraPos.z)) * (180 / Math.PI);
		}

		this.camera.updateProjectionMatrix();
		// }

		this.cameraGroup.add(this.camera);

		this.scene = new Scene();
		this.scene.add(this.cameraGroup);
		this.scene.add(this.group);
		this.scene.add(this.groupStatic);

		// if (this.defaults.debug) {
		// 	const axesHelper = new AxesHelper(2000);
		// 	this.scene.add(axesHelper);
		// }

		this.controls = new OrbitControls(this.camera, this.domElement);
		this.controls.enableDamping = true;
		this.controls.enabled = this.defaults.controls;

		this.clock = new Clock();
	}

	static deltaTime() {
		return this.clock.getDelta();
	}

	static time() {
		return this.clock.getElapsedTime();
	}

	static add(__obj) {
		this.group.add(__obj);
	}

	static remove(__obj) {
		this.group.remove(__obj);
	}

	static addStatic(__obj) {
		this.groupStatic.add(__obj);
	}

	static removeStatic(__obj) {
		this.groupStatic.remove(__obj);
	}

	static start() {
		if (this._started && !this._paused) return;

		if (!this._started) this.addEventListeners();

		this._paused = false;
		this._started = true;
		this.clock.start();

		if (this.defaults.controls) this.domElement.style.pointerEvents = 'initial';
		else this.domElement.style.pointerEvents = 'none';
	}

	static stop() {
		if (!this._started) return;
		if (this._paused) return;
		this._paused = true;
		this.clock.stop();
	}

	static addEventListeners() { }

	static removeEventListeners() { }

	static update() {
		if (this.defaults.controls) {
			this.cameraGroup.position.copy(SETTINGS.cameraPos);
			this.controls.update();
			return;
		}

		if (!isTouch && this.rotation) {
			// Mouse
			const center = {
				x: Interaction.positions.mouse.x - Metrics.CENTER_X,
				y: Interaction.positions.mouse.y - Metrics.CENTER_Y
			};
			// Group rotation
			let mx = Maths.map(center.x, - Metrics.CENTER_X, Metrics.CENTER_X, -SETTINGS.mouseX, SETTINGS.mouseX) + Interaction.mouseSpeed.x;
			let my = - Maths.map(center.y, - Metrics.CENTER_Y, Metrics.CENTER_Y, -SETTINGS.mouseY, SETTINGS.mouseY) + Interaction.mouseSpeed.y;

			mx = Maths.clamp(mx, -60, 60);
			my = Maths.clamp(my, -180, 180);
			this.mouse.x = Maths.lerp(this.mouse.x, mx, SETTINGS.mouseLerp);
			this.mouse.y = Maths.lerp(this.mouse.y, my, SETTINGS.mouseLerpY);

			const rotationY = Maths.toRadians(this.mouse.x);
			const rotationX = Maths.toRadians(this.mouse.y);

			if (SETTINGS.groupRotation) {
				this.group.rotation.x = rotationX;
				this.group.rotation.y = rotationY;
			}
		}

		if (!this.defaults.ortho) {
			this.cameraGroup.position.lerp(this.cameraPosition, SETTINGS.cameraLerp);

			this.cameraLookAt.lerp(this.cameraLookAtTarget, SETTINGS.cameraLerp);
			this.camera.lookAt(this.cameraLookAt);
		}
	}

	static render() {
		this.renderer.render(this.scene, this.camera);
	}

	static loop() {
		if (!this._started || this._paused) return;

		this.update();
		this.render();
	}

	static resize() {
		if (isSmartphone) return;

		this.domElement.style = '';
		const { width, height } = this.domElement.getBoundingClientRect();

		if (width === this.size.x && height === this.size.y) return;

		this.size.set(width, height);
		this.renderer.setSize(this.size.x, this.size.y);

		// if (this.defaults.ortho) {
		// 	this.camera.left = -this.size.x / 2;
		// 	this.camera.right = this.size.x / 2;
		// 	this.camera.top = this.size.y / 2;
		// 	this.camera.bottom = -this.size.y / 2;
		// } else {
		this.camera.aspect = this.size.x / this.size.y;

		// this.cameraPosition.z = this.defaults.cameraPos.z;
		this.cameraGroup.position.z = this.defaults.cameraPos.z;

		if (this.defaults.camera2D) {
			this.camera.fov = 2 * Math.atan(this.size.x / this.camera.aspect / (2 * this.defaults.cameraPos.z)) * (180 / Math.PI);
		}
		// }

		this.camera.updateProjectionMatrix();
	}

	static dispose() {
		this.removeEventListeners();
	}
}
